.serviceBack center {
    top: 40%;
    color: #ffffff;
    font-size: 50px;
    position: relative;
    font-weight: bold;
}

.serviceBack {
    position: relative;
    height: 100vh;
    background-size: 100% 100%;
}

.listItem h6 {
    margin: 0px;
    font-size: 20px;
    line-height: 22px;
    padding-top: 10px;
    color: rgba(60, 60, 60, 0.70);
}

.serviceInstruction {
    padding: 30px 30px;
}

.srIcon path {
    stroke: #32A9FD;
}
.serviceInstruction h2{
    margin: 0px;
    padding: 0px 0px 20px 0px;
}
.srintro {
    margin-bottom: 20px;
}
.serviceInstruction h2 {
    color:#808080;
}
.wcus li {
    text-align: center;
    margin-bottom: 10px;
}
.wcus li h6 {
    font-size: 16px;
}
.serviceBack::before {
    background-color: #524e4e;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}