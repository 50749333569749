.srintro {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 40px;
}

.abouClient {
    background: #f7f7f7;
    color: #000000;
    text-align: center;
    font-size: 24px;
    padding: 30px 0px;
    line-height: 45px;
    border-bottom: 1px solid #2E2E2E;
}

.abouClient h2 {
    margin: 0;
    padding: 0px 0px 20px 0px;
    display: inline-block;
    letter-spacing: 1px;
    color: #32A9FD;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    border-bottom: 2px solid #32A9FD;
}

.clientList {
    text-align: center;
    margin: 0;
    padding: 0;
}


.clientList>li {
    display: inline-block;
    text-align: center;
    margin-bottom: 50px;
    float: none;
    vertical-align: top;
    list-style-type: none;
    width: 100px;
    height: 100px;
    margin: 20px;
}

.clientimg {
    border-bottom: 0px solid #2E2E2E;
}

.clientimg {
    background-size: 100%;
    height: 100%;
    background-repeat: no-repeat;
}

.hp {
    background-image: url("../Components/Assets/Logo_HP.svg");
}

.emps {
    background-image: url("../Components/Assets/Logo_HP.svg");
    background-position: 50%;
}

.clientBack {
    background-image: url("../Components/Assets/Clients.jpg");
    background-size: 100%;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
}

.clientBack center {
    top: 40%;
    color: #ffffff;
    font-size: 50px;
    position: relative;
    font-weight: bold;
}

.clientBack {
    height: 100vh;
    background-size: 100% 100%;
}

.clientBack::before {
    background-color: #524e4e;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}