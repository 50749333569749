.carousel-indicators [data-bs-target] {
    border-radius: 100%;
    width: 10px;
    height: 10px;
}

.carousel-indicators [data-bs-target] {
    width: 10px !important;
    height: 10px !important;
}

.carousel-inner .carousel-item::before {
    background-color: #524e4e;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}



.carousel,
.carousel-inner,
.carousel-item {
    height: auto;
}
.carousel-caption {
    padding-top: 0rem;
}
.HP_Contact {
    margin: 20px; 
}
.HP_Contact a {
    border: 2px solid #ffffff;
    margin: 10px;
    padding: 10px;
    border-radius: 30px;
    text-decoration: none;
    color: #ffffff;
}
.geTouch{
    color: #ffffff;
    text-decoration: none;
    font-size: 18px;
}
.geTouch svg{
    stroke: #32A9FD;
    color: #32A9FD;
}
.f16 {
    font-size: 16px;
}
@media (max-width: 767px) {

    .carousel-caption {
        font-size: 14px;
    }
    .d-block {
        height: 100% !important;
    }

    .carousel-caption {
        font-size: 10px !important;
    }

    .Navbar {
        padding: 10px 10px 0px 10px !important;
    }

    .carousel-indicators, .carousel-indicators p {
        margin-bottom: 0rem !important;
    }
    .carousel-caption{
        padding-bottom: 0 !important;
    }
    h3 {
        font-size: calc(1rem + .3vw) !important;
    }
    .f16 {
        font-size: 16px;
    }
}

@media (max-width: 360px) {
    .nav-logo span {
        display: none;
    }

    .d-block {
        height: 100% !important;
    }

    .carousel-caption {
        font-size: 10px !important;
    }

    .Navbar {
        padding: 10px 10px 0px 10px !important;
    }

    .carousel-indicators, .carousel-indicators p {
        margin-bottom: 0rem !important;
    }
    .carousel-caption{
        padding-bottom: 0 !important;
    }
    .w-100 {
       height: 42vh !important;
    }
}