#text-area {
    width: 320px;
    height: 320px;
}
@media (max-width: 1100px) {
    #text-area {
        width: 280px;
        height: 280px;
    }
}
.cntBack {
    height: 100vh;
    position: relative;
    background-size: 100% 100%;
}
.cntBack::before {
    background-color: #524e4e;
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    opacity: 0.5;
}
.form-group-wrap h2 {
    padding-bottom: 20px;
}
@media (max-width: 600px) {
    .form-group p {
        display: table-cell !important;
      }
      #text-area {
        width: 100% !important;
    }
    .form-group{width: 100%;}

}