.instruction{
    background-color: #ffffff;
    color: #000;
    padding: 20px 60px;
}
.instruction p {
    font-size: 26px;
    line-height: 38px;
}
.copy-menu li a {
    text-decoration:  none;
}
.copy-menu li a:hover {
    text-decoration:  underline;
}