.client-header h1 {
    color: white;
    font-size: 34px;
    letter-spacing: 2px;
    line-height: 350px;
    margin: 0 auto;
    text-align: center;
}

.contact-us {
    color: #000000;
    text-align: center;
    font-size: 24px;
    line-height: 45px;
    border-bottom: 1px solid #2E2E2E;
    padding: 20px 0px;
}

.contact-us h5 {
    margin: 0px;
}

.contact-us p {
    margin: 0px;
    font-size: 16px;
    line-height: 20px;
}

.media-publications {
    background: #000;
    color: white;
    text-align: center;
    font-size: 24px;
    padding: 20px 10px;
    line-height: 45px;
    border-bottom: 1px solid #2E2E2E;
}

.mp-logo li {
    display: inline-table;
    padding: 20px;
}

.mp-logo ul li img:hover {
    opacity: 0.5;
}

.page-header-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(60, 60, 60, 0.70);
}

.abtBack,
.serviceBack,
.cntBack {
    background-image: url("../Components/Assets/Clients.jpg");
    /* background-size: 100%; */
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
}

.serviceBack {
    background-image: url("../Components/Assets/Services.jpeg");
}

.cntBack {
    background-image: url("../Components/Assets/Contact.jpg");
}

.serviceBack center {
    top: 40%;
    color: #ffffff;
    font-size: 50px;
    position: relative;
    font-weight: bold;
}

.aboutTeam,
.serviceInstruction {
    background: #fff;
    color: #000000;
    text-align: center;
    font-size: 18px;
    padding: 30px 80px;
    line-height: 45px;
    border-bottom: 1px solid #2E2E2E;
}

.aboutTeam h2 {
    margin: 0;
    padding: 0px 0px 60px 0px;
    display: inline-block;
    letter-spacing: 1px;
    color: #32A9FD;
    text-transform: uppercase;
    font-size: 30px;
    text-align: center;
    border-bottom: 2px solid #32A9FD;
}

.h2Sub {
    padding: 0px 0px 0px 0px;
    margin: 0px;
    position: relative;
    top: -58px;
}

.serviceInstruction .h2Sub {
    top: 0px;
}

.serviceList {
    text-align: center;
    margin: 0;
    padding: 0;
}

.serviceList>li {
    display: inline-block;
    text-align: center;
    margin-bottom: 50px;
    float: none;
    vertical-align: top;
    list-style-type: none;
}

.listItem {
    width: 30%;
}

.srIcon {
    font-size: 52px;
    color: #32A9FD;
}

.listItem h5 {
    margin: 0px;
}

.subItems li {
    color: #32A9FD;
    list-style-type: disc;
    text-align: left;
    font-size: 16px;
    line-height: 20px;
    margin: 10px 0;
}

.subItems li span {
    color: #000000;
}

.srintro {
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .serviceList>li {
        width: 50%;
    }

    .abtBack,
    .cntBack,
    .serviceBack,
    .clientBack {
        position: relative;
        height: 320px !important;
    }

    .abtBack,
    .cntBack,
    .serviceBack,
    .clientBack::before {
        background-color: #524e4e;
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        height: 320px !important;
        width: 100%;
        opacity: 0.5;
    }

    .serviceInstruction {
        padding: 20px 40px;
    }

    .h2Sub p {
        font-size: 20px;
        line-height: 30px;
    }

    .h2Sub {
        top: -58px;
    }

    .serviceInstruction .h2Sub {
        top: 0px;
    }

    .serviceBack center {
        font-size: 25px;
        top: 53%;
    }

    .social-award-icons img {
        width: 100px;
        height: 100px;
        padding: 10px;
    }

    .form-group input {
        width: 92% !important;
    }
}

@media (max-width: 419px) {
    .serviceList>li {
        width: 100%;
    }

    .h2Sub {
        font-size: 18px;
    }

    .nav-logo>span {
        padding: 0px !important;
        display: block;
        position: absolute;
        top: 80px;
        font-size: 16px;
    }

    .abtBack,
    .cntBack,
    .serviceBack,
    .clientBack {
        height: 190px !important;
    }

    .abtBack,
    .cntBack,
    .serviceBack,
    .clientBack::before {
        background-color: #524e4e;
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        height: 190px !important;
        width: 100%;
        opacity: 0.5;
    }

    .text-area-group {
        position: relative;
    }

    .form-group p {
        display: table-cell !important;
    }

    #text-area {
        width: 100% !important;
    }

    .clientBack center,
    .serviceBack center {
        top: 66% !important;
        font-size: 30px !important;
    }
}