.privacy .Navbar {
    background-color: #4D5656;
    position: relative;
}
.privacyBlock {
    background: #ffffff;
    color: #000000;
    margin: 20px 20px;
    text-align: center;
}
.privacyBlock h6 {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: #32A9FD;
    border-bottom: 2px solid #32A9FD;
}
.privacyBlock ol{
    padding: 20px;
    text-align: left;
    color: #32A9FD;
    list-style-type: none;
}
.privacyBlock ol li {
    padding-bottom: 20px;
    font-weight: bold;
}

.sublist li{
    padding-bottom: 0px !important;
}
.pd10{
    padding-bottom: 10px !important;
}
.inContent{
    color: #000000;
    font-size: 14px;
    line-height: 22px;
    font-weight: normal !important;
}
.inContent p {
    margin: 0%;
}
.sublist{
    padding-bottom: 10px !important;
}
.sublist li{
    font-weight: normal !important;
}
.cali p{
 padding-bottom: 4px !important;
}