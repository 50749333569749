.copy-menu {
    gap: 10px;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
}

.copy-menu li {
    display: flex;
    align-items: right;
    justify-content: right;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
}

.footer-content {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  color: #090000;
  text-align: center;
  height: 40px; /* Adjust according to your footer height */
  line-height: 40px;
}

@media (max-width: 460px) {
    .social-icons li svg {
        font-size: 34px;
    }
}