.abtBack {
    height: 660px;
    background-size: 100% 100%;
}
.teamList {
    text-align: center;
}

.trustHeader {
    text-align: center;
    color: rgb(230, 154, 31);
    font-size: 70px;
}

.trustText {
    text-align: center;
    padding-left: 10%;
    padding-right: 10%;
    font-size: 24px;
}

.teamList > li {
    display: inline-block;
    text-align: center;
    float: none;
    vertical-align: top;
    list-style-type: none;
    width: 250px;
    margin: 50px;
    position: relative;
}

.listItem .description {
    position: absolute;
    top: 31%;
    padding: 10px;
    color: #ffffff;
    opacity: 0;
    visibility: hidden;
}
@media (max-width: 886px) {
.nav-menu li a {
    font-size: 14px;
    margin: 10px;
}
.nav-logo span {
    font-size: 16px;
}
}