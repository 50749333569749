
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  .form{
    display: flex;
    justify-content: center;
  }
  
  .label {
    margin-bottom: 10px;
    font-size: 16px;

  }
  
  .input {
    padding: 10px;
    font-size: 14px;
    
  }
  input{
    border: none;
  }
  
  .button {
    margin-top: 15px;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .form-group{
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 30px;
  }
  .form-group input{
    padding: 10px;
    width: 70%;
  }
  .form-group p{
    width: 400px;
    margin-bottom: 5px;
  }
  .form-group-wrap{
    display: flex;
    margin: 20px 20px;
    flex-wrap: wrap;
    background-color: #f4f7f8;
    border-radius: 20px;
    padding: 10px 30px;
    width: 90%;
  }
  .form-group-wrap h2{
    text-align: center;
    line-height: 35px ;
  }
  .form-group-wrap h1{
    text-align: center;

  }
  #text-area {
    width: 350px;
    height: 350px;
  }
  
  .text-area-group button {
    padding: 30px 39px 18px 39px;
    color: #FFF;
    background-color: #4bc970;
    font-size: 18px;
    text-align: center;
    font-style: normal;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #3ac162;
    border-width: 1px 1px 3px;
    box-shadow: 0 -1px 0 rgba(255,255,255,0.1) inset;
    margin-bottom: 10px;
  }

  .text-area-group {
    display: flex;
    flex-direction: column;
    padding: '30px';
  }