.terms .Navbar {
    background-color: #4D5656;
    position: relative;
}

.termsBlock {
    background: #ffffff;
    color: #000000;
    margin: 20px 20px;
    text-align: center;
}
.termsBlock h6 {
    margin: 0;
    padding: 0;
    display: inline-block;
    color: #32A9FD;
    border-bottom: 2px solid #32A9FD;
}
.termsBlock ol{
    padding: 20px;
    text-align: left;
    color: #32A9FD;
}
.termsBlock ol li {
    padding-bottom: 20px;
}
.sublist li{
    padding-bottom: 0px !important;
}
.pd10{
    padding-bottom: 10px;
}
.inContent{
    color: #000000;
    font-size: 14px;
    line-height: 22px;
}
.inContent p {
    margin: 0%;
}
.sublist{
    padding-bottom: 10px;
}