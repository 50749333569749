.Navbar {
    position: fixed;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
    width: 100%;
    height: 10%;
    z-index: 1000;
    background-color: white;
    color: #181717;
}

.nav-logo {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.logo-phone {
    position: relative;
    color: #32A9FD;
    top: 0px;
    left: 0px;
    font-size: 18px;
}

.nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    color: #000;
    padding: 0px;
    margin: 0px;
}

.nav-menu li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
}

.nav-menu li a {
    text-decoration: none;
    color: #181717;
    width: 110px;
    text-align: center;
}

.nav-menu li:hover {
    margin: -1px;
    border: 1px solid #c7cacbd6;
}

.nav-menu li a:hover {
    color: #181717;
}

.nav-logo img {
    max-width: 80px;
}

.hamburger-menu {
    display: none;
}

@media (max-width: 767px) {
    .nav-menu {
        display: none;
    }

    .hamburger-menu {
        display: block;
        z-index: 999;
        font-size: 26px;
        color: #161515;
    }

    .mobile-nav-menu {
        position: absolute;
        display: flex;
        width: 100%;
        height: 101.5vh;
        background: white;
        z-index: 200;
        text-align: left;
        color: #161515;
        flex-direction: column;
        justify-content: center;
        top: 0px;
        left: 0px;
    }

    .mobile-nav-menu li {
        justify-content: center;
        color: #161515;
        font-size: 28px;
    }

    .mobile-nav-menu li a {
        color: #161515;
    }

    .hamburger-menu a {
        color: #161515;
        vertical-align: middle;
        line-height: 74px;
    }

    .nav-menu li:hover {
        margin: -1px;
        border: 1px solid #c7cacbd6;
    }

    .nav-menu li a:hover {
        color: #161515;
    }

    .cls-color path {
        stroke: #161515;
    }
}